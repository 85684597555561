<template>
  <el-drawer size="70%" :visible.sync="drawerShow" :show-close="false">
    <template slot="title">
      <div class="title">
        <div class="title-text">{{ title }}</div>
        <div class="color-block"></div>
      </div>
    </template>
    <div class="main">
      <div class="his-item" v-for="item in dataList" :key="item.id">
        <!-- 图标 -->
        <div class="pic">
          <img src="./static/t.png" />
        </div>
        <!-- 历史信息 -->
        <div class="his-content">
          <div class="top-title">
            <span class="top-title-left">已生成批单</span>
            <span class="top-title-right">批改被保人 (批改，修改)</span>
          </div>
          <div class="change-info">
            <span>申请时间:{{ item.createTime }}</span>
            <span>生效时间:{{ item.effectiveTime }}</span>
            <span>批单号:{{ item.riderNumber }}</span>
            <span>保单号:{{ item.policyNo }}</span>
            <span>保费变化:{{ item.changeAmount }}</span>
          </div>
          <div class="approval">批改详情：{{ item.approval }}</div>
        </div>
      </div>
      <div
        v-if="!dataList || !dataList.length"
        style="text-align: center;padding: 12px;"
      >--</div>
    </div>
    <!-- 分页 -->
    <div class="pager">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :total="total"
        :current-page="current"
        :page-size="size"
        @size-change="sizeChange"
        @current-change="currentChange"
        hide-on-single-page
      ></el-pagination>
    </div>
  </el-drawer>
</template>

<script>
import { getCorrectionHistory } from "@/api/policy";
export default {
  name: "HistoricalRecord",
  // model:{
  //   prop:'dataList',
  //   event:'change'
  // },
  props: {
    title: {
      type: String,
      default: "批改历史",
    },
    hisShow: {
      type: Boolean,
      default: false,
    },
    // dataList: {
    //   type: Array,
    //   default: () => [],
    // },
    // getDataList:{
    //   type:Function,
    //   default:()=>{
    //     return ()=>{}
    //   }
    // }
  },
  data() {
    return {
      drawerShow: false,
      dataList: [],
      current: 1,
      size: 10,
      total: 100,
      orderId: "",
    };
  },
  watch: {
    hisShow(n) {
      this.drawerShow = n;
    },
    drawerShow(n) {
      this.$emit("update:hisShow", n);
    },
  },
  methods: {
    async getDataList(item = { id: this.orderId }) {
      this.orderId = item.id;
      getCorrectionHistory({
        orderId: this.orderId,
        size: this.size,
        current: this.current,
      }).then(
        (res) => {
          this.total = res.data.total;
          this.dataList = res.data.records;
        },
        (err) => { }
      );
    },
    sizeChange(size) {
      this.size = size;
      this.getDataList();
    },
    currentChange(current) {
      this.current = current;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer {
}
::v-deep .el-drawer {
  background: #ffffff;
  box-shadow: -3px 0px 10px 1px rgba(120, 120, 120, 0.3);
  border-radius: 8px 0px 0px 8px;
  opacity: 1;
}
::v-deep .el-drawer__header {
  margin-bottom: 24px;
}
.title {
  display: column;
  justify-content: flex-start;
  margin-bottom: none !important;
  .title-text {
    font-size: 18px;
    font-weight: bold;
    color: #4278c9;
    line-height: 15px;
    text-align: left;
  }
  .color-block {
    width: 88px;
    height: 6px;
    background: linear-gradient(-90deg, #ffffff 0%, #a4c9ff 100%);
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
  }
}
.main {
  display: column;
  padding: 0 20px;
  .his-item {
    display: flex;
    width: 100%;
    //min-height: 192px;
    background: #ffffff;
    border-radius: 8px;
    opacity: 1;
    border: 1px solid #cccccc;
    padding: 0 12px 24px 16px;
    margin-bottom: 24px;
    .pic {
      margin: 8px 8px 0 0;
    }
    .his-content {
      margin-top: 21px;
      .top-title {
        .top-title-left {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .top-title-right {
          margin-left: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
      .change-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 24px;
        > span {
          font-size: 14px;
          font-weight: 400;
          margin-top: 12px;
          color: #333333;
          min-width: 240px;
          display: block;
        }
      }
      .approval {
        font-weight: 400;
        color: #333333;
        font-size: 14px;
      }
    }
  }
}
.pager {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 24px 48px 0;
  text-align: right;
}
</style>
